<template>
  <div class="">
    <b-row class="m-0">
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="12"
          md="10"
          lg="8"
          class="px-xl-2 mx-auto"
        >
          <b-card :title="$t('Generate Redeem codes').toUpperCase()">
            <!-- form -->
            <validation-observer
              ref="generateCodesForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="generate"
              >
                <!-- Coach email -->
                <b-form-group
                  :label="$t('Coach email')"
                  label-for="coach-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Coach email')"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="coach-email"
                      v-model="coachEmail"
                      :state="errors.length > 0 ? false : null"
                      name="coach-email"
                      placeholder="user@mail.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Redeem Code Name -->
                <b-form-group
                  :label="$t('Redeem code name')"
                  label-for="redeem-code-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Redeem code name')"
                    rules="required"
                  >
                    <b-form-input
                      id="redeem-code-name"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      name="redeem-code-name"
                      placeholder="CODE50"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Redeem Code Type -->
                <b-form-group
                  :label="$t('Redeem code type')"
                  label-for="redeem-code-type"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Redeem code type')"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="type"
                      label="text"
                      :reduce="o => o.value"
                      :placeholder="$t('No Type')"
                      :options="licenseTypes"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Redeem Codes -->
                <b-form-group
                  :label="$t('Number')"
                  label-for="number"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Number')"
                    :rules="{required: startDate === null || endDate === null, greaterThanZero: true}"
                  >
                    <b-form-input
                      id="number"
                      v-model="number"
                      :state="errors.length > 0 ? false : null"
                      name="number"
                      placeholder="0"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Percent -->
                <b-form-group
                  :label="$t('Percent')"
                  label-for="percent"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Percent')"
                    rules="required|greaterThanZero"
                  >
                    <b-form-input
                      id="Percent"
                      v-model="percent"
                      :state="errors.length > 0 ? false : null"
                      name="number"
                      placeholder="0%"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Start/End Date -->
                <b-row class="mb-2">
                  <b-col>
                    <label for="datepicker-start">{{ $t('From') }}</label>
                    <b-form-datepicker
                      id="datepicker-start"
                      v-model="startDate"
                      v-bind="labels[$i18n.locale] || {}"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      reset-button
                      :locale="$i18n.locale"
                      :min="new Date()"
                      :max="max"
                      :state="startDateState"
                    />
                  </b-col>
                  <b-col>
                    <label for="datepicker-end">{{ $t('To') }}</label>
                    <b-form-datepicker
                      id="datepicker-end"
                      v-model="endDate"
                      v-bind="labels[$i18n.locale] || {}"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      reset-button
                      :locale="$i18n.locale"
                      :min="min"
                      :state="endDateState"
                    />
                  </b-col>
                </b-row>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || isLoading"
                >
                  {{ $t("Generate").toUpperCase() }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  email,
  greaterThanZero,
} from '@validations'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      coachEmail: null,
      name: null,
      type: 0,
      number: null,
      percent: null,
      startDate: null,
      endDate: null,
      isLoading: false,

      // validation rules
      required,
      email,
      greaterThanZero,

      labels: {
        fr: {
          weekdayHeaderFormat: 'narrow',
          labelPrevDecade: 'Décennie précédente',
          labelPrevYear: 'Année précédente',
          labelPrevMonth: 'Mois précédent',
          labelCurrentMonth: 'Mois en cours',
          labelNextMonth: 'Prochain mois',
          labelNextYear: 'Année prochaine',
          labelNextDecade: 'Prochaine décennie',
          labelToday: 'Aujourd\'hui',
          labelSelected: 'Selectionnée',
          labelNoDateSelected: 'Pas de date choisie',
          labelCalendar: 'Calendrier',
          labelNav: 'Navigation',
          labelHelp: 'Utilisez les flèches du clavier pour nagviguer',
        },
      },
      licenseTypes: [
        { text: this.$t('FREEMIUM'), value: 0, type: 'FREEMIUM' },
        { text: this.$t('PERFORMANCE'), value: 1, type: 'PERFORMANCE' },
        { text: this.$t('TOUR'), value: 2, type: 'TOUR' },
      ],
    }
  },
  computed: {
    min() {
      return this.startDate
    },
    max() {
      return this.endDate
    },
    startDateState() {
      if (this.number) {
        return true
      }
      return this.isNotBlank(this.startDate)
    },
    endDateState() {
      if (this.number) {
        return true
      }
      return this.isNotBlank(this.endDate)
    },
  },
  methods: {
    resetForm() {
      this.name = ''
      this.percent = ''
      this.number = ''
      this.startDate = ''
      this.endDate = ''
      this.type = 0
      this.coachEmail = ''
    },
    isNotBlank(value) {
      return value !== null && value !== undefined && value.length !== 0
    },
    async generate() {
      this.isLoading = true
      const isValid = await this.$refs.generateCodesForm.validate()
      if (!isValid) return
      const body = {
        code: this.name.trim(),
        percent: this.percent,
        max_redemptions: this.number,
        start_date: this.$dayjs(`${this.startDate} 00:00:00`, 'YYYY-MM-dd HH:mm:ss'),
        end_date: this.$dayjs(`${this.endDate} 23:59:59`, 'YYYY-MM-dd HH:mm:ss'),
        type: this.licenseTypes.find(item => item.value === this.type).type,
        coach_email: this.coachEmail.trim(),
      }
      try {
        const response = await axios.post('/api/subscription/promotion-codes/', body)
        if (!response) return
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: this.$t('The redeem code has been generated.'),
          },
        })
        // reset form
        this.resetForm()
        await this.$refs.generateCodesForm.reset()
      } catch (e) {
        let errorMsg = this.$t("We couldn't process your request. Please contact us at support@spidergolf.fr")
        if (!e || !e.response || !e.response.data) return
        if (e.response.status === 400 || e.response.status === 404) {
          if (e.response.data.message) {
            errorMsg = this.$t(e.response.data.message)
          } else if (e.response.data.code) {
            errorMsg = this.$t(e.response.data.code[0])
          } else if (e.response.data.email) {
            errorMsg = this.$t(e.response.data.end_date[0])
          }
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Request failed'),
            icon: 'SlashIcon',
            variant: 'danger',
            text: errorMsg,
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

small {
  color: $blue;
}
</style>
