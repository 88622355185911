var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"12"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"12","md":"10","lg":"8"}},[_c('b-card',{attrs:{"title":_vm.$t('Generate Redeem codes').toUpperCase()}},[_c('validation-observer',{ref:"generateCodesForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.generate($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Coach email'),"label-for":"coach-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Coach email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"coach-email","state":errors.length > 0 ? false : null,"name":"coach-email","placeholder":"user@mail.com"},model:{value:(_vm.coachEmail),callback:function ($$v) {_vm.coachEmail=$$v},expression:"coachEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Redeem code name'),"label-for":"redeem-code-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Redeem code name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"redeem-code-name","state":errors.length > 0 ? false : null,"name":"redeem-code-name","placeholder":"CODE50","type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Redeem code type'),"label-for":"redeem-code-type"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Redeem code type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"type","label":"text","reduce":function (o) { return o.value; },"placeholder":_vm.$t('No Type'),"options":_vm.licenseTypes,"clearable":false},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Number'),"label-for":"number"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Number'),"rules":{required: _vm.startDate === null || _vm.endDate === null, greaterThanZero: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number","state":errors.length > 0 ? false : null,"name":"number","placeholder":"0","type":"number"},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Percent'),"label-for":"percent"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Percent'),"rules":"required|greaterThanZero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Percent","state":errors.length > 0 ? false : null,"name":"number","placeholder":"0%","type":"number"},model:{value:(_vm.percent),callback:function ($$v) {_vm.percent=$$v},expression:"percent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('label',{attrs:{"for":"datepicker-start"}},[_vm._v(_vm._s(_vm.$t('From')))]),_c('b-form-datepicker',_vm._b({attrs:{"id":"datepicker-start","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"reset-button":"","locale":_vm.$i18n.locale,"min":new Date(),"max":_vm.max,"state":_vm.startDateState},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'b-form-datepicker',_vm.labels[_vm.$i18n.locale] || {},false))],1),_c('b-col',[_c('label',{attrs:{"for":"datepicker-end"}},[_vm._v(_vm._s(_vm.$t('To')))]),_c('b-form-datepicker',_vm._b({attrs:{"id":"datepicker-end","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"reset-button":"","locale":_vm.$i18n.locale,"min":_vm.min,"state":_vm.endDateState},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'b-form-datepicker',_vm.labels[_vm.$i18n.locale] || {},false))],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t("Generate").toUpperCase())+" ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }